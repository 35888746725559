import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

export const getExpenseCategory = createAsyncThunk('expenseSlice/getExpenseCategory', async () => {
    try {
        let response = await axios.get('/ExpenseCategory', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createExpenseCategory = createAsyncThunk('expenseSlice/createExpenseCategory', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/ExpenseCategory', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        toast.success(response?.data?.message || 'Expense Category created successfully.');
        await dispatch(getExpenseCategory());

        return Promise.resolve(response.data.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        return rejectWithValue(err);
    }
});

export const updateExpenseCategory = createAsyncThunk('expenseSlice/updateExpenseCategory', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/ExpenseCategory/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        toast.success(response?.data?.message || 'State updated successfully.');
        await dispatch(getExpenseCategory());

        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        return rejectWithValue(err);
    }
});

export const deleteExpenseCategory = createAsyncThunk('expenseSlice/deleteExpenseCategory', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/ExpenseCategory/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        toast.success(response?.data?.message || 'Seasons deleted successfully');
        await dispatch(getExpenseCategory());

        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateStatus = createAsyncThunk('expenseSlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/ExpenseCategory/status/' + id, undefined, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        await dispatch(getExpenseCategory());

        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.err(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

// export const getExpenseCategoryOptions = createAsyncThunk('expenseSlice/getExpenseCategoryOptions', async (data) => {
//     try {
//         const query = queryString.stringify(data);
//         let response = await axios.get('/ExpenseCategory/options' + `?${query}`, {
//             headers: {
//                 'Content-Type': 'application/json'
//             }
//         });
//         return Promise.resolve(response.data.data);
//     } catch (err) {
//         toast.error(err?.response?.data?.message || 'Something went wrong!');
//         console.log('err', err);

//         return Promise.reject(err);
//     }
// });
export const getExpenseCategoryOptions = createAsyncThunk('expenseSlice/getExpenseCategoryOptions', async () => {
    try {
        let response = await axios.get('/ExpenseCategory?Option=1', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('Error:', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const expenseSlice = createSlice({
    name: 'expenseSlice',
    initialState: {
        data: [],
        options: []
    },

    extraReducers: (builder) => {
        builder.addCase(getExpenseCategory.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getExpenseCategoryOptions.fulfilled, (state, action) => {
            state.options = action?.payload;
            return;
        });
    }
});

export default expenseSlice.reducer;
