// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit';

// ** ThemeConfig
import themeConfig from '../configs/themeConfig';

import SecureLS from 'secure-ls';

export const secureLs = new SecureLS({
    encodingType: 'aes',
    encryptionSecret: 'easymba-admin-panel',
    isCompression: false
});

const initialActiveTab = () => {
    const item = window.localStorage.getItem('activeTab');

    //** Parse stored json or if none return initialValue

    return item ? JSON.parse(item) : themeConfig.layout.activeTab;
};
const initialRequestTab = () => {
    const item = window.localStorage.getItem('requestTab');

    //** Parse stored json or if none return initialValue

    return item ? JSON.parse(item) : themeConfig.layout.requestTab;
};

const initialProjectTab = () => {
    const item = window.localStorage.getItem('projectTab');

    //** Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : themeConfig.layout.projectTab;
};

const initialModuleTab = () => {
    const item = window.localStorage.getItem('moduleTab');

    //** Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : themeConfig.layout.moduleTab;
};

// const initialModuleSelectedData = () => {
//     const item = window.localStorage.getItem('moduleSelectedData');

//     //** Parse stored json or if none return initialValue
//     return item ? JSON.parse(item) : themeConfig.layout.moduleSelectedData || {};
// };

const initialModuleSelectedData = () => {
    try {
        const item = window.localStorage.getItem('MSD');
        return item ? JSON.parse(item) : themeConfig.layout.moduleSelectedData; // Ensure it's at least an empty object
    } catch (error) {
        console.error('Error parsing moduleSelectedData from localStorage:', error);
        return {}; // Fallback to empty object
    }
};
// const initialModuleShowProject = () => {
//     const item = window.localStorage.getItem('moduleShowProject');

//     //** Parse stored json or if none return initialValue
//     return item ? JSON.parse(item) : themeConfig.layout.moduleShowProject;
// };

const initialModuleShowProject = () => {
    try {
        const item = window.localStorage.getItem('MSP');
        return item ? JSON.parse(item) : themeConfig.layout.moduleShowProject; // Ensure fallback is false
    } catch (error) {
        console.error('Error parsing moduleShowProject from localStorage:', error);
        return false; // Fallback to false
    }
};
export const tabSlice = createSlice({
    name: 'tabSliceData',
    initialState: {
        activeTab: initialActiveTab(),
        requestTab: initialRequestTab(),
        projectTab: initialProjectTab(),
        moduleTab: initialModuleTab(),
        moduleSelectedData: initialModuleSelectedData(),
        moduleShowProject: initialModuleShowProject()
    },
    reducers: {
        handleActiveTab: (state, action) => {
            state.activeTab = action.payload;
            localStorage.setItem('activeTab', JSON.stringify(action.payload));
        },
        removeTabs: (state, action) => {
            state.activeTab = '1';
            localStorage.setItem('activeTab', '1');
        },
        handleRequestTab: (state, action) => {
            state.requestTab = action.payload;
            localStorage.setItem('requestTab', JSON.stringify(action.payload));
        },
        removeRequestTabs: (state, action) => {
            state.requestTab = '1';
            localStorage.setItem('requestTab', '1');
        },
        handleProjectTab: (state, action) => {
            state.projectTab = action.payload;
            localStorage.setItem('projectTab', JSON.stringify(action.payload));
        },
        handleModuleTab: (state, action) => {
            state.moduleTab = action.payload;
            localStorage.setItem('moduleTab', JSON.stringify(action.payload));

            if (action.payload === false) {
                // Store additional Task props in localStorage when switching to Task
                localStorage.setItem('MSD', JSON.stringify(state.moduleSelectedData));
                localStorage.setItem('MSP', JSON.stringify(state.moduleShowProject));
            } else {
                // Remove them when switching back to Modules
                localStorage.removeItem('MSD');
                localStorage.removeItem('MSP');
            }
        },

        handleModuleSelectedData: (state, action) => {
            state.moduleSelectedData = action.payload;
            localStorage.setItem('MSD', JSON.stringify(action.payload));
        },
        handleModuleShowProject: (state, action) => {
            state.moduleShowProject = action.payload;
            localStorage.setItem('MSP', JSON.stringify(action.payload));
        },
        removeProjectTabs: (state, action) => {
            state.projectTab = '1';
            localStorage.setItem('projectTab', '1');
        },
        removeModuleTabs: (state, action) => {
            state.moduleTab = 'true';
            localStorage.setItem('moduleTab', 'true');
        }
    }
});

export const {
    handleActiveTab,
    removeTabs,
    handleRequestTab,
    removeRequestTabs,
    handleProjectTab,
    handleModuleTab,
    handleModuleSelectedData,
    handleModuleShowProject,
    removeProjectTabs,
    removeModuleTabs
} = tabSlice.actions;

export default tabSlice.reducer;
