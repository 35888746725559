import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

export const getExpense = createAsyncThunk('expensesSlice/getExpense', async () => {
    try {
        let response = await axios.get('/Expense', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createExpense = createAsyncThunk('expensesSlice/createExpense', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/Expense', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Expense  created successfully.');
        return Promise.resolve(response.data.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        return rejectWithValue(err);
    }
});

export const getEmployees = createAsyncThunk('expensesSlice/getEmployees', async () => {
    try {
        let response = await axios.get('/Employee/expense-employee', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');

        return Promise.reject(err);
    }
});
export const updateExpense = createAsyncThunk('expensesSlice/updateExpense', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/Expense/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        toast.success(response?.data?.message || 'Expense updated successfully.');
        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        return rejectWithValue(err);
    }
});

export const createVoucher = createAsyncThunk('expensesSlice/createVoucher', async (data, { dispatch, rejectWithValue }) => {
    try {
        // let response = await axios.put(`/Expense/paid/${data?.id}`, data?.data, {
        let response = await axios.put('/Expense/paid/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Voucher Expense Created successfully.');
        return Promise.resolve(response.data.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        return rejectWithValue(err);
    }
});

export const deleteExpense = createAsyncThunk('expensesSlice/deleteExpense', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/Expense/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
            // loader: false
        });
        toast.success(response?.data?.message || 'Seasons deleted successfully');
        // await dispatch(getFilterExpense());

        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateStatus = createAsyncThunk('expensesSlice/updateStatus', async ({ id }) => {
    try {
        let response = await axios.put('/Expense/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getFilterExpense = createAsyncThunk('expensesSlice/getFilterExpense', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/Expense/report', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        return rejectWithValue(err);
    }
});

export const expensesSlice = createSlice({
    name: 'expensesSlice',
    initialState: {
        data: [],
        employeeOption: []
    },

    extraReducers: (builder) => {
        builder.addCase(getFilterExpense.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getEmployees.fulfilled, (state, action) => {
            state.employeeOption = action.payload;
            return;
        });
    }
});

export default expensesSlice.reducer;
