// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';
import queryString from 'query-string';

import { toast } from 'react-toastify';

export const getProject = createAsyncThunk('projectData/getProject', async () => {
    try {
        let response = await axios.get('/project', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getProjectById = createAsyncThunk('projectData/getProjectById', async (Id) => {
    try {
        let response = await axios.get('/project/' + Id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getTaskByProjectId = createAsyncThunk('projectData/getTaskByProjectId', async (Id) => {
    try {
        let response = await axios.get('/project/tasks/' + Id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getMembersByProjectId = createAsyncThunk('projectData/getMembersByProjectId', async (Id) => {
    try {
        let response = await axios.get('/project/members/' + Id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getProjectMembersOptions = createAsyncThunk('projectData/getProjectMembersOptions', async (Id) => {
    try {
        let response = await axios.get(`/project/members/${Id}?option=true`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getProjectOption = createAsyncThunk('projectData/getProjectOption', async () => {
    try {
        let response = await axios.get('/project?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const deleteProject = createAsyncThunk('projectData/deleteProject', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/project/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Project deleted successfully.');
        await dispatch(getProject());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const deleteProjectMember = createAsyncThunk('projectData/deleteProjectMember', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/project/member/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Project deleted successfully.');
        await dispatch(getProject());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createProject = createAsyncThunk('projectData/createProject', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/project', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Project added succesfully.');
        await dispatch(getProject());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createProjectMember = createAsyncThunk('projectData/createProjectMember', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/project/member', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Project added succesfully.');
        await dispatch(getProject());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createProjectManageMember = createAsyncThunk('projectData/createProjectManageMember', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/project/manage-member', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Project added succesfully.');
        data?.loader && (await dispatch(getProject()));
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateProject = createAsyncThunk('projectData/updateProject', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/project/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Project updated succesfully.');
        await dispatch(getProject());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateProjectMember = createAsyncThunk('projectData/updateProjectMember', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/project/drag-member/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // toast.success(response?.data?.message || 'Project updated succesfully.');
        // await dispatch(getProject());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateProjectStatus = createAsyncThunk('projectData/updateProjectStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/project/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status Updated Succesfully');
        await dispatch(getProject());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createProjectModule = createAsyncThunk('projectData/createProjectModule', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/ProjectModule', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Module added successfully.');
        // await dispatch(getProjectModule());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong 34234!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const updateProjectModule = createAsyncThunk('projectData/updateProjectModule', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/ProjectModule/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Module update successfully.');
        // await dispatch(getProjectModule());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

// export const updateProjectModuleStatus = createAsyncThunk('projectData/updateProjectModuleStatus', async (id, { dispatch }) => {
//     try {
//         let response = await axios.put('/ProjectModule/module-status/' + id, undefined, {
//             headers: {
//                 'Content-Type': 'application/json'
//             },
//             loader: true
//         });
//         toast.success(response?.data?.message || 'Status updated successfully.');

//         return Promise.resolve(response.data.data);
//     } catch (err) {
//         toast.err(err?.response?.data?.message || 'Something went wrong!');
//         return Promise.reject(err);
//     }
// });
export const updateProjectModuleStatus = createAsyncThunk(
    'projectData/updateProjectModuleStatus',
    async ({ moduleId, status }, { dispatch }) => {
        try {
            const response = await axios.put(`/ProjectModule/module-status/${moduleId}?status=${status}`, undefined, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            toast.success(response?.data?.message || 'Status updated successfully.');
            // await dispatch(getProjectModule());
            return Promise.resolve(response.data.data);
        } catch (err) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return Promise.reject(err);
        }
    }
);

// export const updateProjectModuleStatus = createAsyncThunk(
//     'projectData/updateProjectModuleStatus',
//     async ({ id, status }, { dispatch }) => {
//         try {
//             let response = await axios.put(
//                 `/ProjectModule/module-status/${id}`,
//                 { status }, // Send status in the request body
//                 {
//                     headers: {
//                         'Content-Type': 'application/json',
//                     },
//                     loader: true,
//                 }
//             );
//             toast.success(response?.data?.message || 'Status updated successfully.');
//             await dispatch(getFabric());

//             return Promise.resolve(response.data.data);
//         } catch (err) {
//             toast.error(err?.response?.data?.message || 'Something went wrong!');
//             return Promise.reject(err);
//         }
//     }
// );

export const deleteProjectModule = createAsyncThunk('projectData/deleteProjectModule', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/ProjectModule/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Project deleted successfully.');
        // await dispatch(getProjectModule());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getProjectModule = createAsyncThunk('projectData/getProjectModule', async (id) => {
    try {
        let response = await axios.get('/ProjectModule/by-project/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const getProjectModuleOption = createAsyncThunk('projectData/getProjectModuleOption', async (id) => {
    try {
        let response = await axios.get(`/ProjectModule/${id}?Option=true`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getTaskByModuleId = createAsyncThunk('projectData/getTaskByModuleId', async (id) => {
    try {
        let response = await axios.get('/Task/by-module/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const getGroupByProjectId = createAsyncThunk('projectData/getGroupByProjectId', async (data) => {
    try {
        let response = await axios.get(`/Task/group-by/${data.id}?Groupby=` + data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const getFilterTaskByProjectId = createAsyncThunk('projectData/getFilterTaskByProjectId', async (data) => {
    try {
        let response = await axios.post('/Task/filter-task', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getTaskTypeByProjectId = createAsyncThunk('projectData/getTaskTypeByProjectId', async (data) => {
    try {
        const queryParams = {};
        if (data?.isSubTask === 'true') {
            queryParams.isSubTask = 'true';
        }

        const queryStringValue = queryString.stringify(queryParams);
        const url = `/Project/type/${data?.projectId}${queryStringValue ? `?${queryStringValue}` : ''}`;
        let response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getStateByProjectId = createAsyncThunk('projectData/getStateByProjectId', async (data) => {
    try {
        let response = await axios.get('/Project/state/' + data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createTask = createAsyncThunk('projectData/createTask', async (data) => {
    try {
        let response = await axios.post('/Task', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Task added successfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong 34234!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const updateTask = createAsyncThunk('projectData/updateTask', async (data) => {
    try {
        let response = await axios.put('/Task/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // toast.success(response?.data?.message || 'Task added successfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong 34234!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const createTaskLink = createAsyncThunk('projectData/createTaskLink', async (data) => {
    try {
        let response = await axios.post('/task/TaskLinks', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Link added successfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong 34234!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const updateTaskLink = createAsyncThunk('projectData/updateTaskLink', async (data) => {
    try {
        let response = await axios.put('/task/TaskLinks/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // toast.success(response?.data?.message || 'Task added successfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong 34234!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const getTaskLinkById = createAsyncThunk('projectData/getTaskLinkById', async (id) => {
    try {
        let response = await axios.get('/Task/TaskLinks/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const deleteTaskLink = createAsyncThunk('projectData/deleteTaskLink', async (data) => {
    try {
        let response = await axios.delete('/task/TaskLinks/' + data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const getActivityByTaskId = createAsyncThunk('projectData/getActivityByTaskId ', async (data) => {
    try {
        const typeQuery = data.type ? `&type=${data.type}` : '';
        const orderByQuery = data.orderBy ? `&orderBy=${data.orderBy}` : '';
        let response = await axios.get(
            '/task/TaskActivity/' + data.id + `?page=${data.page}&pageSize=${data.pageSize}${typeQuery}${orderByQuery}`,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );
        return Promise.resolve(response?.data);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createTaskReaction = createAsyncThunk('projectData/createTaskReaction', async (data) => {
    try {
        let response = await axios.post('/Task/TaskReaction', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createTaskActivity = createAsyncThunk('projectData/createTaskActivity', async (data) => {
    try {
        let response = await axios.post('/task/TaskActivity', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateTaskActivity = createAsyncThunk('projectData/updateTaskActivity', async (data) => {
    try {
        let response = await axios.put('/task/TaskActivity/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const deleteTaskActivity = createAsyncThunk('projectData/deleteTaskActivity', async (data) => {
    try {
        let response = await axios.delete('/task/TaskActivity/' + data?.id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const createTaskAttachment = createAsyncThunk('projectData/createTaskAttachment', async (data) => {
    try {
        let response = await axios.post('/task/TaskAttachments', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getAttachmentByTaskId = createAsyncThunk('projectData/getAttachmentByTaskId ', async (data) => {
    try {
        const response = await axios.get('/task/TaskAttachments/' + data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const deleteTaskAttachment = createAsyncThunk('projectData/deleteTaskAttachment', async (data) => {
    try {
        let response = await axios.delete('/task/TaskAttachments/' + data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

// Drag task api call
export const dragTask = createAsyncThunk('projectData/dragTask', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/task/drag-task/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // toast.success(response?.data?.message || 'Drag succesfully.');

        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

//task delete
export const deleteTask = createAsyncThunk('projectData/deleteTask', async (id) => {
    try {
        let response = await axios.delete('/task/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getParentByTaskId = createAsyncThunk('projectData/getParentByTaskId ', async (data) => {
    const query = queryString.stringify(data);
    try {
        const response = await axios.get('/task/cluster-task?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getSubTaskByTaskId = createAsyncThunk('projectData/getSubTaskByTaskId ', async (data) => {
    const query = queryString.stringify(data);
    try {
        const response = await axios.get('/Task/sub-task?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const addExistingTask = createAsyncThunk('projectData/addExistingTask', async (data) => {
    try {
        let response = await axios.post('/Task/add-existing/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getStatusOverview = createAsyncThunk('projectData/getStatusOverview', async (data) => {
    try {
        const response = await axios.get('/Task/status-overview/' + data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const getSummaryActivity = createAsyncThunk('projectData/getSummaryActivity', async (data) => {
    try {
        const response = await axios.get('/Task/summary-activity/' + data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const getPriorityOverview = createAsyncThunk('projectData/getPriorityOverview', async (data) => {
    try {
        const response = await axios.get('/Task/priority-overview/' + data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const getTypeOfWork = createAsyncThunk('projectData/getTypeOfWork', async (data) => {
    try {
        const response = await axios.get('/Task/types-of-work/' + data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const getTeamWorkLoad = createAsyncThunk('projectData/getTeamWorkLoad', async (data) => {
    try {
        const response = await axios.get('/Task/team-workload/' + data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const getModuleProgress = createAsyncThunk('projectData/getModuleProgress', async (data) => {
    try {
        const response = await axios.get('/Task/module-progress/' + data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const yourProjectSlice = createSlice({
    name: 'yourProjectData',
    initialState: {
        data: [],
        project: {},
        members: [],
        options: [],
        module: [],
        moduleData: [],
        taskData: [],
        groupByData: [],
        taskFilterData: [],
        taskTypeData: [],
        stateData: [],
        taskLink: [],
        taskReaction: [],
        taskActivity: [],
        moduleOption: [],
        taskAttachment: [],
        taskParent: [],
        taskSubtask: [],
        statusOverview: [],
        summaryActivity: [],
        priorityOverview: [],
        typeOfWork: [],
        teamWorkload: [],
        moduleProgress: [],
        projectMemberOptions: []
    },
    reducers: {
        removeActivityChange: (state) => {
            state.taskActivity = [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getProject.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getProjectOption.fulfilled, (state, action) => {
            state.options = action?.payload;
            return;
        });
        builder.addCase(getProjectMembersOptions.fulfilled, (state, action) => {
            state.projectMemberOptions = action?.payload;
            return;
        });
        builder.addCase(getProjectById.fulfilled, (state, action) => {
            state.project = action?.payload;
            return;
        });
        builder.addCase(getTaskByProjectId.fulfilled, (state, action) => {
            state.taskData = action?.payload;
            return;
        });
        builder.addCase(getMembersByProjectId.fulfilled, (state, action) => {
            state.members = action?.payload;
            return;
        });
        builder.addCase(getProjectModule.fulfilled, (state, action) => {
            state.moduleData = action?.payload;
            return;
        });
        builder.addCase(getTaskByModuleId.fulfilled, (state, action) => {
            state.taskData = action?.payload;
            return;
        });
        builder.addCase(getGroupByProjectId.fulfilled, (state, action) => {
            state.groupByData = action?.payload;
            return;
        });
        builder.addCase(getFilterTaskByProjectId.fulfilled, (state, action) => {
            state.taskFilterData = action?.payload;
            return;
        });
        builder.addCase(getTaskTypeByProjectId.fulfilled, (state, action) => {
            state.taskTypeData = action?.payload;
            return;
        });
        builder.addCase(getStateByProjectId.fulfilled, (state, action) => {
            state.stateData = action?.payload;
            return;
        });
        builder.addCase(getTaskLinkById.fulfilled, (state, action) => {
            state.taskLink = action?.payload;
            return;
        });
        builder.addCase(getActivityByTaskId.fulfilled, (state, action) => {
            state.taskActivity = action?.payload;
            return;
        });
        builder.addCase(getProjectModuleOption.fulfilled, (state, action) => {
            state.moduleOption = action?.payload;
            return;
        });
        builder.addCase(getAttachmentByTaskId.fulfilled, (state, action) => {
            state.taskAttachment = action?.payload;
            return;
        });
        builder.addCase(getParentByTaskId.fulfilled, (state, action) => {
            state.taskParent = action?.payload;
            return;
        });
        builder.addCase(getSubTaskByTaskId.fulfilled, (state, action) => {
            state.taskSubtask = action?.payload;
            return;
        });
        builder.addCase(getStatusOverview.fulfilled, (state, action) => {
            state.statusOverview = action?.payload;
            return;
        });
        builder.addCase(getSummaryActivity.fulfilled, (state, action) => {
            state.summaryActivity = action?.payload;
            return;
        });
        builder.addCase(getPriorityOverview.fulfilled, (state, action) => {
            state.priorityOverview = action?.payload;
            return;
        });
        builder.addCase(getTypeOfWork.fulfilled, (state, action) => {
            state.typeOfWork = action?.payload;
            return;
        });
        builder.addCase(getTeamWorkLoad.fulfilled, (state, action) => {
            state.teamWorkload = action?.payload;
            return;
        });
        builder.addCase(getModuleProgress.fulfilled, (state, action) => {
            state.moduleProgress = action?.payload;
            return;
        });
    }
});
export const { removeActivityChange } = yourProjectSlice.actions;

export default yourProjectSlice.reducer;
