// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

export const getTypeAndState = createAsyncThunk('typeAndStateSliceData/getTypeAndState', async (id, { dispatch }) => {
    try {
        let response = await axios.get('/Project/type-and-state/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        await dispatch(clearTypeAndState());
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createTypeAndState = createAsyncThunk('typeAndStateSliceData/createTypeAndState', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/Project/type-and-state', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Type and State added successfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

// export const updateTypeAndState = createAsyncThunk('typeAndStateSliceData/updateTypeAndState', async (data, { dispatch }) => {
//     try {
//         let response = await axios.put('/Project/type-and-state/' + data?.id, data?.data, {
//             headers: {
//                 'Content-Type': 'application/json'
//             }
//         });
//         toast.success(response?.data?.message || 'Type and State updated successfully.');
//         await dispatch(getTypeAndState());
//         return Promise.resolve(response);
//     } catch (err) {
//         toast.error(err?.response?.data?.message || 'Something went wrong!');
//         console.log('err', err);
//         return Promise.reject(err);
//     }
// });

// export const updateTaskStateStatus = createAsyncThunk('typeAndStateSliceData/updateTaskStateStatus', async (id, { dispatch }) => {
//     try {
//         let response = await axios.put('/Project/type-and-state/status/' + id, {
//             headers: {
//                 'Content-Type': 'application/json'
//             }
//         });
//         toast.success(response?.data?.message || 'Status Updated Succesfully');
//         await dispatch(getTypeAndState());
//         return Promise.resolve(response);
//     } catch (err) {
//         toast.error(err?.response?.data?.message || 'Something went wrong!');
//         console.log('err', err);
//         return Promise.reject(err);
//     }
// });
export const taskStateSlice = createSlice({
    name: 'typeAndStateSliceData',
    initialState: {
        typeAndState: []
        // typeAndStateStatus: []
    },
    reducers: {
        clearTypeAndState: (state, action) => {
            state.typeAndState = [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getTypeAndState.fulfilled, (state, action) => {
            state.typeAndState = action?.payload;
            return;
        });
        // builder.addCase(getTaskStateStatus.fulfilled, (state, action) => {
        //     state.typeAndStateStatus = action?.payload;
        //     return;
        // });
    }
});
export const { clearTypeAndState } = taskStateSlice.actions;

export default taskStateSlice.reducer;
