// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

export const getReaction = createAsyncThunk('reactionSliceData/getReaction', async () => {
    try {
        let response = await axios.get('/Reaction', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const getReactionStatus = createAsyncThunk('reactionSliceData/getReactionStatus', async () => {
    try {
        let response = await axios.get('/Reaction?status=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const deleteReaction = createAsyncThunk('reactionSliceData/deleteReaction', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/Reaction/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Reaction deleted successfully.');
        await dispatch(getReaction());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createReaction = createAsyncThunk('reactionSliceData/createReaction', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/Reaction', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Reaction added succesfully.');
        await dispatch(getReaction());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateReaction = createAsyncThunk('reactionSliceData/updateReaction', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/Reaction/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Reaction updated succesfully.');
        await dispatch(getReaction());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateReactionStatus = createAsyncThunk('reactionSliceData/updateReactionStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/Reaction/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status Updated Succesfully');
        await dispatch(getReaction());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const reactionSlice = createSlice({
    name: 'reactionSliceData',
    initialState: {
        reaction: [],
        reactionStatus: []
    },
    extraReducers: (builder) => {
        builder.addCase(getReaction.fulfilled, (state, action) => {
            state.reaction = action?.payload;
            return;
        });
        builder.addCase(getReactionStatus.fulfilled, (state, action) => {
            state.reactionStatus = action?.payload;
            return;
        });
    }
});

export default reactionSlice.reducer;
