// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

export const getTaskState = createAsyncThunk('taskStateSliceData/getTaskState', async () => {
    try {
        let response = await axios.get('/TaskState', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const getTaskStateStatus = createAsyncThunk('taskStateSliceData/getTaskStateStatus', async () => {
    try {
        let response = await axios.get('/TaskState?status=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const deleteTaskState = createAsyncThunk('taskStateSliceData/deleteTaskState', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/TaskState/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Task State deleted successfully.');
        await dispatch(getTaskState());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createTaskState = createAsyncThunk('taskStateSliceData/createTaskState', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/TaskState', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Task State added succesfully.');
        await dispatch(getTaskState());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateTaskState = createAsyncThunk('taskStateSliceData/updateTaskState', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/TaskState/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Task State updated succesfully.');
        await dispatch(getTaskState());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateTaskStateStatus = createAsyncThunk('taskStateSliceData/updateTaskStateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/TaskState/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status Updated Succesfully');
        await dispatch(getTaskState());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const taskStateSlice = createSlice({
    name: 'taskStateSliceData',
    initialState: {
        taskState: [],
        taskStateStatus: []
    },
    extraReducers: (builder) => {
        builder.addCase(getTaskState.fulfilled, (state, action) => {
            state.taskState = action?.payload;
            return;
        });
        builder.addCase(getTaskStateStatus.fulfilled, (state, action) => {
            state.taskStateStatus = action?.payload;
            return;
        });
    }
});

export default taskStateSlice.reducer;
